<template>
  <div class="relative">
    <transition name="fade">
      <div
        :class="{ 'is-full-page': isFullPage }"
        v-show="isActive"
        :aria-busy="isActive"
        tabindex="0"
        class="loading-overlay is-active z-[99]"
        aria-label="Loading"
      >
        <div
          @click.prevent="cancel"
          :style="bgStyle"
          class="loading-background"
        ></div>
        <div class="loading-icon">
          <IconsSpinner :color="color" :width="45" :height="45" />
        </div>
      </div>
    </transition>
    <div v-if="!isHidden">
      <slot />
    </div>
    <div v-else :class="hiddenClass"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  color: {
    type: String,
    default: '#000',
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  isFullPage: {
    type: Boolean,
    default: false,
  },
  isHidden: {
    type: Boolean,
    default: false,
  },
  hiddenClass: {
    type: String,
    default: 'h-32',
  },
})
const bgStyle = computed(() => {
  return {
    background: '#f8f9fa',
    opacity: 0.9,
    backdropFilter: `blur(2px)`,
  }
})
</script>
<style scoped>
.loading-overlay {
  @apply inset-0 absolute items-center hidden justify-center overflow-hidden;
}

.loading-overlay.is-active {
  @apply flex;
}

.loading-overlay.is-full-page {
  @apply fixed z-[9999];
}

.loading-overlay .loading-background {
  @apply inset-0 absolute bg-white opacity-50;
}
.loading-icon {
  @apply z-[999999];
}
.loading-overlay .loading-icon,
.loading-parent {
  @apply relative;
}
</style>
